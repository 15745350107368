import { AxiosError } from 'axios';
import { ApiException } from './exceptions';
import appService from './services/app';
import type * as Params from '../types/param';
import type * as Entity from '../types/entity';

export const getCoaps = async (
  params: Params.GetCoaps
): Promise<Entity.List<Entity.Coap>> => {
  try {
    const { data } = await appService.get<Entity.List<Entity.Coap>>(
      '/v1/coaps',
      {
        params,
      }
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const getCoapById = async (id: string): Promise<Entity.Coap> => {
  try {
    const { data } = await appService.get<Entity.Coap>(`/v1/coaps/${id}`);
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const generateRedeemTokenByCoapId = async (
  coapId: string
): Promise<Entity.RedeemToken> => {
  try {
    const { data } = await appService.post<Entity.RedeemToken>(
      '/v1/coaps/_generate_redeem_token',
      { id: coapId }
    );
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};
