import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AsyncState } from './types/state';
import useAppDispatch from './redux/hooks/useAppDispatch';
import useAppSelector from './redux/hooks/useAppSelector';
import * as userThunk from './redux/thunks/user';
import WithNav from './outlets/WithNav';
import Login from './pages/Login';
import Verify from './pages/Verify';
import Coaps from './pages/Coaps';
import CoapById from './pages/CoapById';
import Profile from './pages/Profile';
import ProfileUpdate from './pages/ProfileUpdate';

function App() {
  const dispatch = useAppDispatch();
  const getUserStatus = useAppSelector((state) => state.user.getUserStatus);

  useEffect(() => {
    dispatch(userThunk.getUser());
  }, [dispatch]);

  if (getUserStatus === AsyncState.PENDING) {
    return null;
  }

  return (
    <Routes>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/verify'} element={<Verify />} />
      <Route path={'/'} element={<WithNav />}>
        <Route path={'/'} element={<Coaps />} />
        <Route path={'coaps/:id'} element={<CoapById />} />
        <Route path={'/profile'} element={<Profile />} />
        <Route path={'/profile/edit'} element={<ProfileUpdate />} />
      </Route>
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
}

export default App;
