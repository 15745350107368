import { useCallback } from 'react';
import {
  Container,
  Center,
  Text,
  Box,
  HStack,
  Spinner,
  useBoolean,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AsyncState } from '../../types/state';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as userThunk from '../../redux/thunks/user';
import Header from '../../components/Header';
import Countdown from 'react-countdown';

export default function Verify() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signInStatus = useAppSelector((state) => state.user.signInStatus);
  const emailAddress = useAppSelector((state) => state.user.emailAddress);
  const [isResend, setIsResend] = useBoolean();

  const handleSubmitOtp = useCallback(
    async (otp: string) => {
      try {
        await dispatch(userThunk.signIn(otp)).unwrap();
        navigate('/', { replace: true });
      } catch (e) {}
    },
    [dispatch, navigate]
  );

  const handleResendOtp = useCallback(async () => {
    if (!emailAddress) return;
    setIsResend.on();
    await dispatch(userThunk.signUp(emailAddress));
  }, [dispatch, emailAddress, setIsResend]);

  if (!emailAddress) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Header />
      <Center h={'calc(100vh - 250px)'}>
        <Box textAlign={'center'} p={'0px 20px 0px 20px'}>
          <Text fontSize={'lg'}>
            Check your email inbox for an one time password
          </Text>
          <Text fontSize={'lg'} fontWeight={'300'} mb={'15px'}>
            Enter the one time password below
          </Text>
          <Center mb={'25px'}>
            <HStack
              position={'relative'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Spinner
                display={signInStatus === AsyncState.PENDING ? 'block' : 'none'}
                position={'absolute'}
                size="lg"
                zIndex={2}
                color={'coap.blue.5'}
              />
              <PinInput
                otp
                size={'lg'}
                onComplete={(otp) => handleSubmitOtp(otp)}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Center>
          {signInStatus === AsyncState.REJECTED && (
            <Text color={'red'}>Invalid one time password.</Text>
          )}
          <Text fontSize={'md'} fontWeight={'300'}>
            <Box as={'span'} mr={'5px'}>
              Did not receive a code?
            </Box>
            {isResend ? (
              <Countdown
                date={Date.now() + 59000}
                onComplete={() => setIsResend.off()}
                renderer={({ seconds }) => (
                  <Text as={'span'}>
                    Wait until {seconds} seconds to resend again
                  </Text>
                )}
              />
            ) : (
              <Box
                as="span"
                cursor={'pointer'}
                userSelect={'none'}
                color={'coap.blue.5'}
                onClick={handleResendOtp}
              >
                Resend
              </Box>
            )}
          </Text>
        </Box>
      </Center>
    </Container>
  );
}
