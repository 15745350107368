import { HStack, Image, Spacer, Center } from '@chakra-ui/react';
import CoapLogoBlue from '../../images/coap_logo_blue.png';

export default function Header() {
  return (
    <HStack
      w={'100%'}
      h={'70px'}
      bgColor={'#ffffff'}
      p={['0px 20px 0px 20px', '0px 50px 0px 50px']}
      boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
      bg={'coap.yellow.1'}
      zIndex={1000}
      overflow={'hidden'}
    >
      <Center w={'100%'}>
        <HStack gap={'10px'} w={'100%'} maxW={'1440px'}>
          <Image src={CoapLogoBlue} width={100} draggable={false} />
          <Spacer />
        </HStack>
      </Center>
    </HStack>
  );
}
