import {
  HStack,
  VStack,
  Image,
  Show,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  useBoolean,
  Spacer,
  Avatar,
  Text,
  Center,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import useAppSelector from '../../redux/hooks/useAppSelector';
import CoapLogoBlue from '../../images/coap_logo_blue.png';

export default function TopNavbar() {
  const [open, setOpen] = useBoolean();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  return (
    <HStack
      w={'100%'}
      h={'50px'}
      bgColor={'#ffffff'}
      p={['0px 20px 0px 20px', '0px 50px 0px 50px']}
      position={'fixed'}
      boxShadow={open ? 'none' : 'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
      zIndex={1000}
      bg={'coap.yellow.1'}
      overflow={'hidden'}
    >
      <Center w={'100%'}>
        <HStack gap={'10px'} w={'100%'} maxW={'1440px'}>
          <Image
            src={CoapLogoBlue}
            width={100}
            onClick={() => navigate('/')}
            cursor={'pointer'}
            draggable={false}
          />
          <Spacer />
          <HStack alignItems={'center'}>
            <Show above={'md'}>
              <Avatar
                name={user?.display_name ? user?.display_name : user?.email}
                size={'sm'}
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
              />
              <Text>{user?.web3_account}</Text>
            </Show>
            <IconButton
              aria-label={'Hamburger button'}
              icon={<HamburgerIcon />}
              variant={'unstyled'}
              onClick={setOpen.toggle}
            />
          </HStack>
        </HStack>
      </Center>
      <Drawer
        placement={'right'}
        onClose={setOpen.off}
        isOpen={open}
        closeOnEsc
      >
        <DrawerOverlay />
        <DrawerContent zIndex={2000} bgColor={'#ffffff'}>
          <DrawerHeader>
            <HStack gap={'10px'}>
              <Avatar
                name={user?.display_name ? user?.display_name : user?.email}
                size={'sm'}
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
              />
              <Box>
                <Text fontSize={'md'}>{user?.display_name}</Text>
                <Text fontSize={'sm'} fontWeight={'light'}>
                  {user?.email}
                </Text>
              </Box>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <VStack
              pt={'5px'}
              pb={'5px'}
              gap={'5px'}
              alignItems={'flex-star'}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              <Box
                as={NavLink}
                fontSize={'md'}
                fontWeight={'normal'}
                onClick={setOpen.off}
                to={'/profile'}
              >
                Profile
              </Box>
              <Box
                as={NavLink}
                fontSize={'md'}
                fontWeight={'normal'}
                onClick={setOpen.off}
                to={'/'}
              >
                Coaps
              </Box>
              <Box
                cursor={'pointer'}
                fontSize={'md'}
                fontWeight={'normal'}
                onClick={() => {
                  window.localStorage.removeItem('coap_app_access_token');
                  window.location.replace('/login');
                }}
              >
                Sign out
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
}
