import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as authApi from '../../apis/auth';
import * as userApi from '../../apis/user';
import * as Param from '../../types/param';
import isNil from 'lodash.isnil';
import { ApiException } from '../../apis/exceptions';

export const getUser = createAppAsyncThunk('user/getUser', async () => {
  return userApi.getUser();
});

export const signUp = createAppAsyncThunk(
  'user/signUp',
  async (email: string, store) => {
    try {
      const { code } = await authApi.signUp({ email });

      if (code) {
        console.log('[DEBUG] OTP code: ', code);
      }

      return email;
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);

export const signIn = createAppAsyncThunk(
  'account/signIn',
  async (code: string, store) => {
    try {
      const state = store.getState();
      const email = state.user.emailAddress;

      if (isNil(email)) {
        return store.rejectWithValue({
          msg: 'Not supposed to reach here',
          code: 0,
        });
      }
      const { token } = await authApi.signIn({ email, code });
      localStorage.setItem('coap_app_access_token', token);

      await store.dispatch(getUser());
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);

export const updateUser = createAppAsyncThunk(
  'account/updateUser',
  async (args: Param.UpdateUser, store) => {
    try {
      return await userApi.updateUser(args);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);
