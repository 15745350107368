import { useEffect, useState, useCallback } from 'react';
import {
  Center,
  SimpleGrid,
  Container,
  Text,
  Spinner,
  Image,
  Box,
  useToast,
} from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';
import OhImg from '../../images/coap_btn_oh.png';
import Web3WorldImg from '../../images/coap_web3_world_no_word.png';
import { AsyncState } from '../../types/state';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as coapThunk from '../../redux/thunks/coap';
import Card from '../../components/Card';

export default function Coaps() {
  const dispatch = useAppDispatch();
  const coapList = useAppSelector((state) => state.coap.coapList);
  const hasMoreCoap = useAppSelector((state) => state.coap.hasMoreCoap);
  const getCoapListStatus = useAppSelector(
    (state) => state.coap.getCoapListStatus
  );
  const [page, setPage] = useState(1);
  const toast = useToast();

  const renderSpinner = useCallback(() => {
    if (getCoapListStatus === AsyncState.PENDING) {
      <Spinner size={'xl'} thickness={'5px'} color={'coap.blue.5'} />;
    }

    return null;
  }, [getCoapListStatus]);

  const renderPlaceholder = useCallback(() => {
    if (getCoapListStatus === AsyncState.SUCCESS && coapList.length <= 0) {
      return (
        <Box>
          <Center>
            <Image src={OhImg} w={'300px'} mb={'20px'} draggable={false} />
          </Center>
          <Text
            fontSize={'xl'}
            fontWeight={'light'}
            textAlign={'center'}
            userSelect={'none'}
          >
            Oh... It seems you haven't claimed any COAP NFTs yet...
          </Text>
        </Box>
      );
    }

    return null;
  }, [coapList.length, getCoapListStatus]);

  const renderList = useCallback(() => {
    if (getCoapListStatus === AsyncState.SUCCESS && coapList.length > 0) {
      return (
        <SimpleGrid columns={[1, 2, 3, 4, 5, 6]} spacing={'10px'} w={'full'}>
          {coapList.map((coap) => (
            <Card
              key={coap.id}
              id={coap.id}
              name={coap.t_name}
              img={coap.t_image_uri}
              claimedAt={coap.claimed_at}
              type={coap.type}
            />
          ))}
          {hasMoreCoap && (
            <Waypoint onEnter={() => setPage((prevState) => prevState + 1)} />
          )}
        </SimpleGrid>
      );
    }
    return null;
  }, [coapList, getCoapListStatus, hasMoreCoap]);

  const getCoaps = useCallback(async () => {
    try {
      await dispatch(coapThunk.getCoaps({ limit: 20, page })).unwrap();
    } catch (e: any) {
      if (e.code === 40001) return;

      toast({
        title: `Error ${e.code}`,
        description: e.msg,
        position: 'top',
        status: 'error',
      });
    }
  }, [dispatch, page, toast]);

  useEffect(() => {
    getCoaps();
  }, [getCoaps]);

  return (
    <Container
      maxW={'1440px'}
      pt={['50px', '50px', '260px']}
      pb={'100px'}
      bgImg={Web3WorldImg}
      bgRepeat={'no-repeat'}
      bgSize={['1440px']}
      bgPosition={'center -175px'}
    >
      <Center>
        {renderSpinner()}
        {renderPlaceholder()}
        {renderList()}
      </Center>
    </Container>
  );
}
