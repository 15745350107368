import { createReducer } from '@reduxjs/toolkit';
import type * as Entity from '../../types/entity';
import { AsyncState } from '../../types/state';
import * as coapThunk from '../thunks/coap';
import * as coapAction from '../actions/coap';

interface CoapState {
  coapList: Entity.Coap[];
  hasMoreCoap: boolean;
  totalCoap: number;
  getCoapListStatus: AsyncState;
  coap: Entity.Coap | null;
  getCoapByIdStatus: AsyncState;
  generateRedeemTokenByCoapIdStatus: AsyncState;
  redeemToken: Entity.RedeemToken | null;
}

const initialState: CoapState = {
  coapList: [],
  hasMoreCoap: false,
  totalCoap: 0,
  getCoapListStatus: AsyncState.IDLE,
  coap: null,
  getCoapByIdStatus: AsyncState.IDLE,
  generateRedeemTokenByCoapIdStatus: AsyncState.IDLE,
  redeemToken: null,
};

const coapReducer = createReducer(initialState, (builder) => {
  builder.addCase(coapThunk.getCoaps.pending, (state) => {
    state.getCoapListStatus = AsyncState.PENDING;
  });

  builder.addCase(coapThunk.getCoaps.fulfilled, (state, { payload }) => {
    state.coapList = payload.data;
    state.totalCoap = payload.total;
    state.hasMoreCoap = payload.has_more;
    state.getCoapListStatus = AsyncState.SUCCESS;
  });

  builder.addCase(coapThunk.getCoaps.rejected, (state) => {
    state.getCoapListStatus = AsyncState.REJECTED;
  });

  builder.addCase(coapThunk.getCoapById.pending, (state) => {
    state.getCoapByIdStatus = AsyncState.PENDING;
  });

  builder.addCase(coapThunk.getCoapById.fulfilled, (state, { payload }) => {
    state.coap = payload;
    state.getCoapByIdStatus = AsyncState.SUCCESS;
  });

  builder.addCase(coapThunk.getCoapById.rejected, (state) => {
    state.getCoapByIdStatus = AsyncState.REJECTED;
  });

  builder.addCase(coapThunk.generateRedeemTokenByCoapId.pending, (state) => {
    state.generateRedeemTokenByCoapIdStatus = AsyncState.PENDING;
  });

  builder.addCase(
    coapThunk.generateRedeemTokenByCoapId.fulfilled,
    (state, { payload }) => {
      state.generateRedeemTokenByCoapIdStatus = AsyncState.SUCCESS;
      state.redeemToken = payload;
    }
  );

  builder.addCase(coapThunk.generateRedeemTokenByCoapId.rejected, (state) => {
    state.generateRedeemTokenByCoapIdStatus = AsyncState.REJECTED;
  });

  builder.addCase(coapAction.clearCurrentRedeemToken, (state) => {
    state.redeemToken = null;
  });
});

export default coapReducer;
