import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as coapApi from '../../apis/coap';
import { ApiException } from '../../apis/exceptions';
import type * as Params from '../../types/param';

export const getCoaps = createAppAsyncThunk(
  'coap/getCoaps',
  async (params: Params.GetCoaps, store) => {
    try {
      return await coapApi.getCoaps(params);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);

export const getCoapById = createAppAsyncThunk(
  'coap/getCoapById',
  async (id: string, store) => {
    try {
      return await coapApi.getCoapById(id);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);

export const generateRedeemTokenByCoapId = createAppAsyncThunk(
  'coap/generateRedeemTokenByCoapId',
  async (coapId: string, store) => {
    try {
      return await coapApi.generateRedeemTokenByCoapId(coapId);
    } catch (e) {
      if (e instanceof ApiException) {
        return store.rejectWithValue({
          msg: e.msg,
          code: e.code,
        });
      }

      return store.rejectWithValue({
        msg: 'Unknown error',
        code: 0,
      });
    }
  }
);
