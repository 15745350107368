import { useEffect, useCallback, useRef, useState } from 'react';
import {
  Center,
  Image,
  Box,
  Text,
  Divider,
  Container,
  Heading,
  Link,
  Show,
  Wrap,
  WrapItem,
  Skeleton,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import ReactCardFlip from 'react-card-flip';
import QRCode from 'qrcode';
import { AsyncState } from '../../types/state';
import { useParams, useNavigate } from 'react-router-dom';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as coapThunk from '../../redux/thunks/coap';
import config from '../../config';
import PolygonLogo from '../../images/polygon_matic_logo.png';
import Web3WorldImg from '../../images/coap_web3_world_no_word.png';

export default function TokenById() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const coap = useAppSelector((state) => state.coap.coap);
  const getCoapByIdStatus = useAppSelector(
    (state) => state.coap.getCoapByIdStatus
  );
  const { id } = useParams();
  const [isFlipCoap, setIsFlipCoap] = useBoolean();
  const [qrCodeImgSrc, setQrCodeImgSrc] = useState<string | undefined>();
  const toast = useToast();

  const generateRedeemToken = useCallback(async () => {
    try {
      const { token } = await dispatch(
        coapThunk.generateRedeemTokenByCoapId(id as string)
      ).unwrap();

      const qrCodeSrc = await QRCode.toDataURL(token);

      setQrCodeImgSrc(qrCodeSrc);
    } catch (e: any) {
      if (e.code === 40001) return;

      toast({
        title: `Error ${e.code}`,
        description: `${e.msg}`,
        status: 'error',
        position: 'top',
      });
    }
  }, [dispatch, id, toast]);

  const getCoapById = useCallback(async () => {
    try {
      await dispatch(coapThunk.getCoapById(id as string)).unwrap();
    } catch (e: any) {
      if (e.code === 40001) return;

      navigate('/coaps', { replace: true });

      if (e.code === 40004) {
        toast({
          title: `Error ${e.code}`,
          description: 'Campaign not found',
          status: 'error',
          position: 'top',
        });
        return;
      }

      toast({
        title: `Error ${e.code}`,
        description: `${e.msg}`,
        status: 'error',
        position: 'top',
      });
    }
  }, [dispatch, id, navigate, toast]);

  useEffect(() => {
    getCoapById();
    generateRedeemToken();

    const interval = setInterval(generateRedeemToken, 1000 * 60 * 2);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, generateRedeemToken, getCoapById, id]);

  return (
    <Container
      maxW={'1440px'}
      pt={['50px', '50px', '260px']}
      bgImg={Web3WorldImg}
      bgRepeat={'no-repeat'}
      bgSize={['1440px']}
      bgPosition={'center -175px'}
    >
      <Center mb={'10px'}>
        <Skeleton
          isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}
          h={'300px'}
          w={'300px'}
          overflow={'hidden'}
        >
          <ReactCardFlip isFlipped={isFlipCoap} flipDirection={'horizontal'}>
            <Image
              zIndex={3}
              borderRadius={'10px'}
              loading={'lazy'}
              objectFit={'scale-down'}
              src={coap?.t_image_uri}
              onClick={setIsFlipCoap.on}
              width={300}
              height={300}
              bgColor={'#ffffff'}
            />
            <Image
              w={300}
              h={300}
              zIndex={3}
              borderRadius={'10px'}
              objectFit="cover"
              src={qrCodeImgSrc}
              onClick={setIsFlipCoap.off}
            />
          </ReactCardFlip>
        </Skeleton>
      </Center>
      <Center my={'20px'}>
        <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
          <Box>
            <Heading fontWeight={'700'} size={'md'}>
              {coap?.c_title}
            </Heading>
            <Text fontSize={'sm'}>
              Claimed on{' '}
              {coap?.claimed_at &&
                format(new Date(coap?.claimed_at), 'yyyy-MM-dd')}
            </Text>
          </Box>
        </Skeleton>
      </Center>
      <Center my={'20px'}>
        <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
          <Wrap spacing={'10px'} justify={'center'} maxW={'768px'}>
            {coap?.t_attrs.map((attr, i) => {
              return (
                <WrapItem key={i}>
                  <Box
                    borderRadius={'10px'}
                    borderWidth={'1px'}
                    borderStyle={'solid'}
                    borderColor={'coap.blue.5'}
                    bgColor={'coap.blue.2'}
                    textAlign={'center'}
                    p={'5px 10px 5px 10px'}
                    flex={1}
                  >
                    <Box
                      color={'coap.blue.5'}
                      fontSize={'lg'}
                      fontWeight={'700'}
                      mb={'5px'}
                    >
                      {attr.trait_type}
                    </Box>
                    <Box color={'grey'} fontSize={'lg'}>
                      {attr.value}
                    </Box>
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </Skeleton>
      </Center>
      <Divider my={'10px'} />
      <Show below={'sm'}>
        <Box p={'0px 20px 20px 20px'}>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Token type
              </Heading>
              <Text fontWeight={'light'}>{coap?.type}</Text>
            </Box>
          </Skeleton>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Token title
              </Heading>
              <Text fontWeight={'light'}>{coap?.t_name}</Text>
            </Box>
          </Skeleton>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Token description
              </Heading>
              <Text fontWeight={'light'}>{coap?.t_desc}</Text>
            </Box>
          </Skeleton>
          <Skeleton
            isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}
            display={coap?.issuer_info?.name ? 'block' : 'none'}
          >
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Token Issuer
              </Heading>
              <Text fontWeight={'light'}>{coap?.issuer_info?.name}</Text>
            </Box>
          </Skeleton>
          <Skeleton
            isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}
            display={coap?.issuer_info?.website ? 'block' : 'none'}
          >
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Token Issuer Website
              </Heading>
              <Link fontWeight={'light'} isExternal>
                {coap?.issuer_info?.website} <ExternalLinkIcon />
              </Link>
            </Box>
          </Skeleton>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Campaign title
              </Heading>
              <Text fontWeight={'light'}>
                {coap?.c_title
                  ? coap?.c_title
                  : 'This campaign does not have a title'}
              </Text>
            </Box>
          </Skeleton>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Campaign content
              </Heading>
              <Text fontWeight={'light'}>
                {coap?.c_content
                  ? coap?.c_content
                  : 'This campaign does not have a content'}
              </Text>
            </Box>
          </Skeleton>
          <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
            <Box my={'20px'}>
              <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                Campaign description
              </Heading>
              <Text fontWeight={'light'}>
                {coap?.c_desc
                  ? coap?.c_desc
                  : 'This campaign does not have a description'}
              </Text>
            </Box>
          </Skeleton>
          <Link
            display={'flex'}
            alignItems={'center'}
            href={`${config.polygonUrl.AurumCOAP}?a=${coap?.token_id}`}
            isExternal
            gap={'5px'}
          >
            <Image src={PolygonLogo} width={3} height={3} />
            <span>Read more about this token on Polygon</span>
            <ExternalLinkIcon />
          </Link>
        </Box>
      </Show>

      <Show above={'sm'}>
        <Center>
          <Box w={'100%'} p={'20px 50px 20px 50px'}>
            <Box textAlign={'center'}>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Token type
                </Heading>
                <Text
                  fontWeight={'light'}
                  mb={'20px'}
                  textTransform={'capitalize'}
                >
                  {coap?.type}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Token name
                </Heading>
                <Text fontWeight={'light'} mb={'20px'}>
                  {coap?.t_name}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Token description
                </Heading>
                <Text fontWeight={'light'} mb={'20px'}>
                  {coap?.t_desc}
                </Text>
              </Skeleton>
              <Skeleton
                display={coap?.issuer_info?.name ? 'block' : 'none'}
                isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}
              >
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Token Issuer
                </Heading>
                <Text fontWeight={'light'} mb={'20px'}>
                  {coap?.issuer_info?.name}
                </Text>
              </Skeleton>
              <Skeleton
                display={coap?.issuer_info?.website ? 'block' : 'none'}
                isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}
                mb={'20px'}
              >
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Token Issuer Website
                </Heading>
                <Link fontWeight={'light'} isExternal>
                  {coap?.issuer_info?.website} <ExternalLinkIcon />
                </Link>
              </Skeleton>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Campaign name
                </Heading>
                <Text fontWeight={'light'} mb={'20px'}>
                  {coap?.c_title
                    ? coap?.c_title
                    : 'This campaign does not have a title'}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Campaign content
                </Heading>
                <Text fontWeight={'light'} mb={'20px'}>
                  {coap?.c_content
                    ? coap?.c_content
                    : 'This campaign does not have a content'}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={getCoapByIdStatus === AsyncState.SUCCESS}>
                <Heading fontWeight={'700'} size={'sm'} color={'coap.blue.5'}>
                  Campaign description
                </Heading>
                <Text fontWeight={'light'}>
                  {coap?.c_desc
                    ? coap?.c_desc
                    : 'This campaign does not have a description'}
                </Text>
              </Skeleton>
              <Center mt={'10px'}>
                <Link
                  display={'flex'}
                  alignItems={'center'}
                  href={coap?.t_scan_url}
                  isExternal
                  gap={'5px'}
                >
                  <Image src={PolygonLogo} width={3} height={3} />
                  <span>Read more about this token on Polygon</span>
                  <ExternalLinkIcon />
                </Link>
              </Center>
            </Box>
          </Box>
        </Center>
      </Show>
    </Container>
  );
}
