import { Container, Box } from '@chakra-ui/react';
import { AsyncState } from '../../types/state';
import { Outlet, Navigate } from 'react-router-dom';
import TopNavbar from '../../components/TopNavbar';
// import BottomTabBar from '../../components/BottomTabBar';
import useAppSelector from '../../redux/hooks/useAppSelector';

export default function WithNav() {
  const getUserStatus = useAppSelector((state) => state.user.getUserStatus);

  if (getUserStatus === AsyncState.REJECTED) {
    return <Navigate to={'/login'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <TopNavbar />
      <Box h={'100vh'} w={'100%'} pt={'50px'}>
        <Outlet />
      </Box>
      {/* <BottomTabBar /> */}
    </Container>
  );
}
