import {
  Container,
  Flex,
  Show,
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  Image,
  Text,
  VStack,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useNavigate, Navigate } from 'react-router-dom';
import ev from 'email-validator';
import CoapWhiteLogo from '../../images/coap_logo_white.png';
import MgmtLogo from '../../images/coap_btn_mgmt.png';
import Reward2Logo from '../../images/coap_btn_rewards_2.png';
import DataLogo from '../../images/coap_btn_data.png';
import Web3WorldLogo from '../../images/coap_web3_world_no_word.png';
import { AsyncState } from '../../types/state';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as userThunk from '../../redux/thunks/user';

export default function Login() {
  const dispatch = useAppDispatch();
  const signUpStatus = useAppSelector((state) => state.user.signUpStatus);
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      await dispatch(userThunk.signUp(email));
      navigate('/verify', { replace: true });
    },
    validate: ({ email }) => {
      if (!ev.validate(email)) {
        return {
          email: 'Invalid email address',
        };
      }
    },
    validateOnChange: false,
  });

  if (user) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Flex direction={'row'}>
        <Show above={'sm'}>
          <VStack
            userSelect={'none'}
            flex={1}
            h={'100vh'}
            bgColor={'coap.blue.5'}
            p={'50px'}
            alignItems={'start'}
          >
            <Image src={CoapWhiteLogo} w={'150px'} />
            <Spacer />
            <Text fontWeight={'semibold'} color={'#ffffff'} fontSize={'4xl'}>
              Welcome to Your Ultimate Web3 Reward Hub
            </Text>
            <VStack alignItems={'start'} gap={'10px'}>
              <HStack alignItems={'center'}>
                <Image src={MgmtLogo} h={'70px'} />
                <Text fontSize={'xl'}>Manage Your Digital Assets</Text>
              </HStack>
              <HStack alignItems={'center'}>
                <Image src={Reward2Logo} h={'70px'} />
                <Text fontSize={'xl'}>Rewards with Your Web3 assets</Text>
              </HStack>
              <HStack alignItems={'center'}>
                <Image src={DataLogo} h={'70px'} />
                <Text fontSize={'xl'}>Stay Updated to Latest Web3 Trends</Text>
              </HStack>
            </VStack>
            <Spacer />
          </VStack>
        </Show>
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          w={'425px'}
          h={'100vh'}
          overflowX={'hidden'}
        >
          <VStack justifyContent={'center'} h={'100%'}>
            <Box w={'100%'}>
              <Image src={Web3WorldLogo} transform={'scale(2)'} />
            </Box>
            <Spacer />
            <form
              onSubmit={formik.handleSubmit}
              style={{ width: '100%', padding: 20, marginTop: 50 }}
            >
              <FormControl mb={'20px'} isInvalid={!!formik.errors.email}>
                <FormLabel>Email Address</FormLabel>
                <Input
                  id={'email'}
                  autoComplete={'email'}
                  autoCorrect={'off'}
                  autoFocus={true}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled={signUpStatus === AsyncState.PENDING}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <Button
                width={'full'}
                type={'submit'}
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
                _active={{
                  color: '#ffffff',
                  bgColor: 'coap.blue.5',
                  opacity: '0.5',
                }}
                _hover={{
                  color: '#ffffff',
                  bgColor: 'coap.blue.5',
                  opacity: '0.9',
                }}
                isLoading={signUpStatus === AsyncState.PENDING}
              >
                Sign Up/In
              </Button>
            </form>
            <Text textAlign={'center'} p={'20px'}>
              Your Web3.0 gateway for managing digital assets, offering
              personalized rewards, and driving customer engagement and growth
              across industries.
            </Text>
            <Text fontSize={'xs'} textAlign={'center'} p={'16px'}>
              By proceeding, you agree to our{' '}
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/terms_of_use.html"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/privacy_policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Text>
            <Spacer />
            <Text fontSize={'xs'} fontWeight={'light'} p={'20px'}>
              ©All Rights Reserved COAP® is a registered trademark of Aurum Sky
              Technology LTD. Cookie Preferences,{' '}
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/privacy_policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , and{' '}
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/terms_of_use.html"
                target="_blank"
                rel="noreferrer"
              >
                Terms of use
              </a>
              .
            </Text>
          </VStack>
        </Box>
      </Flex>
    </Container>
  );
}
