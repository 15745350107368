import axios, { AxiosError } from 'axios';
import config from '../../config';

const appService = axios.create({
  baseURL: config.serviceUrl.app,
  timeout: 10000, //10s
});

appService.interceptors.request.use((config) => {
  config.headers['X-Access-Token'] = window.localStorage.getItem(
    'coap_app_access_token'
  );

  return config;
});

appService.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err instanceof AxiosError &&
      err.response?.data.code === 40003 &&
      !['/v1/me', '/v1/signin', '/v1/signup'].includes(err.config?.url || '')
    ) {
      window.localStorage.removeItem('coap_app_access_token');
      window.location.assign('/login');
      return Promise.reject(err);
    }

    return Promise.reject(err);
  }
);

export default appService;
