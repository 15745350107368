import { AxiosError } from 'axios';
import { ApiException } from './exceptions';
import appService from './services/app';
import type * as Param from '../types/param';
import type * as Entity from '../types/entity';

export const getUser = async (): Promise<Entity.User> => {
  try {
    const { data } = await appService.get<Entity.User>('/v1/me');
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const updateUser = async (
  params: Param.UpdateUser
): Promise<Entity.User> => {
  try {
    const { data } = await appService.put<Entity.User>('/v1/me', params);
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};
