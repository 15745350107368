import {
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  CardFooter as ChakraCardFooter,
  Image,
  Text,
  HStack,
  Spacer,
  Badge,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export type CoapCardProps = {
  id: string;
  img: string;
  name: string;
  claimedAt: string;
  type: string;
};

export default function Card(props: CoapCardProps) {
  const navigate = useNavigate();
  const { id, img, name, claimedAt, type } = props;

  return (
    <ChakraCard
      overflow={'hidden'}
      cursor={'pointer'}
      onClick={() => navigate(`/coaps/${id}`)}
    >
      <ChakraCardBody p={1}>
        <Center w={'full'} h={'full'}>
          <Image
            src={img}
            objectFit={'scale-down'}
            minH={200}
            minW={200}
            maxH={200}
            maxW={200}
          />
        </Center>
      </ChakraCardBody>
      <ChakraCardFooter display={'block'}>
        <HStack w={'full'} mb={'10px'}>
          <Text
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            fontWeight={'semibold'}
            fontSize={'sm'}
          >
            {name}
          </Text>
          <Spacer />
          <Badge
            textTransform={'capitalize'}
            fontWeight={'light'}
            border={'0.9px solid black'}
            bgColor={'transparent'}
          >
            {type}
          </Badge>
        </HStack>
        <Text fontSize={'xs'}>
          Claimed on {format(new Date(claimedAt), 'yyyy-MM-dd')}
        </Text>
      </ChakraCardFooter>
    </ChakraCard>
  );
}
