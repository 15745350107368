import { env } from '../env';
const config = {
  // yarn start => 'development'
  // yarn test => 'test'
  // yarn build => 'production'
  // You cannot override NODE_ENV manually. This prevents developers from accidentally deploying a slow development build to production.
  // https://create-react-app.dev/docs/adding-custom-environment-variables/#what-other-env-files-can-be-used
  nodeEnv: env.NODE_ENV || 'development',
  // programmatically defined env
  env: env.REACT_APP_ENV || 'development',
  serviceUrl: {
    app: env.REACT_APP_APP_SERVICE_URL || 'https://dev-api.coap.app/app',
  },
  polygonUrl: {
    AurumCOAP:
      env.REACT_APP_POLYGON_COAP_TOKEN_URL ||
      'https://mumbai.polygonscan.com/token/0x975ddbd1483fc00d0d74294d3b3ec6dda5cce222',
  },
};

export default config;
