import { AxiosError } from 'axios';
import { ApiException } from './exceptions';
import appService from './services/app';
import type * as Params from '../types/param';
import type * as Entity from '../types/entity';

export const signIn = async (params: Params.SignIn): Promise<Entity.SignIn> => {
  try {
    const { data } = await appService.post<Entity.SignIn>('/v1/signin', params);
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};

export const signUp = async (params: Params.SignUp): Promise<Entity.SignUp> => {
  try {
    const { data } = await appService.post<Entity.SignUp>('/v1/signup', params);
    return data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new ApiException(e.response?.data.msg, e.response?.data.code);
    }

    throw e;
  }
};
