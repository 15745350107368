import { createReducer } from '@reduxjs/toolkit';
import type * as Entity from '../../types/entity';
import { AsyncState } from '../../types/state';
import * as userThunk from '../thunks/user';

interface UserState {
  emailAddress: string | null;
  signUpStatus: AsyncState;
  signInStatus: AsyncState;
  user: Entity.User | null;
  getUserStatus: AsyncState;
  updateUserStatus: AsyncState;
}

const initialState: UserState = {
  emailAddress: null,
  signUpStatus: AsyncState.IDLE,
  signInStatus: AsyncState.IDLE,
  user: null,
  getUserStatus: AsyncState.IDLE,
  updateUserStatus: AsyncState.IDLE,
};

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(userThunk.signIn.pending, (state) => {
    state.signInStatus = AsyncState.PENDING;
  });

  builder.addCase(userThunk.signIn.fulfilled, (state) => {
    state.signInStatus = AsyncState.SUCCESS;
  });

  builder.addCase(userThunk.signIn.rejected, (state) => {
    state.signInStatus = AsyncState.REJECTED;
  });

  builder.addCase(userThunk.signUp.pending, (state) => {
    state.signUpStatus = AsyncState.PENDING;
  });

  builder.addCase(userThunk.signUp.fulfilled, (state, { payload }) => {
    state.emailAddress = payload;
    state.signUpStatus = AsyncState.SUCCESS;
  });

  builder.addCase(userThunk.signUp.rejected, (state) => {
    state.signUpStatus = AsyncState.REJECTED;
  });

  builder.addCase(userThunk.getUser.pending, (state) => {
    state.getUserStatus = AsyncState.PENDING;
  });

  builder.addCase(userThunk.getUser.fulfilled, (state, { payload }) => {
    state.user = payload;
    state.emailAddress = payload.email;
    state.getUserStatus = AsyncState.SUCCESS;
  });

  builder.addCase(userThunk.getUser.rejected, (state) => {
    state.getUserStatus = AsyncState.REJECTED;
  });

  builder.addCase(userThunk.updateUser.pending, (state) => {
    state.updateUserStatus = AsyncState.PENDING;
  });

  builder.addCase(userThunk.updateUser.fulfilled, (state, { payload }) => {
    state.user = payload;
    state.updateUserStatus = AsyncState.SUCCESS;
  });

  builder.addCase(userThunk.updateUser.rejected, (state) => {
    state.updateUserStatus = AsyncState.REJECTED;
  });
});

export default userReducer;
