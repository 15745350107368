import {
  Container,
  Avatar,
  HStack,
  Box,
  Text,
  Show,
  VStack,
  Divider,
  // Stat,
  // StatLabel,
  // StatNumber,
  // StatHelpText,
  // StatArrow,
  // StatGroup,
  // Center,
  // useToast,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate, Link } from 'react-router-dom';
import useAppSelector from '../../redux/hooks/useAppSelector';

export default function Profile() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  return (
    <Container maxW={'1024px'} p={['0px 0px 50px 0px', '50px 20px 50px 20px']}>
      <Show above={'sm'}>
        <HStack
          alignItems={'start'}
          w={'100%'}
          h={'calc(100vh - 250px)'}
          gap={'50px'}
        >
          <Box>
            <Avatar
              name={user?.display_name ? user?.display_name : user?.email}
              bgColor={'coap.blue.5'}
              color={'#ffffff'}
              borderRadius={'10px'}
              width={'150px'}
              height={'150px'}
              mb={'20px'}
            />
            <HStack
              as={Link}
              to={'/profile/edit'}
              h={'30px'}
              justifyContent={'space-between'}
              alignItems={'center'}
              cursor={'pointer'}
              _hover={{
                color: 'coap.blue.5',
              }}
            >
              <Text>Edit Profile</Text>
              <ChevronRightIcon />
            </HStack>
          </Box>
          <Box flex={1} wordBreak={'break-word'}>
            <Text fontSize={'2xl'} fontWeight={'semibold'} mb={'20px'}>
              Profile
            </Text>
            <HStack mb={'5px'} alignItems={'start'}>
              <Text fontWeight={'semibold'} fontSize={'sm'} w={'100px'}>
                Display Name
              </Text>
              <Text fontWeight={'light'} fontSize={'sm'}>
                {user?.display_name ? user?.display_name : 'Not provided'}
              </Text>
            </HStack>
            <HStack mb={'5px'} alignItems={'start'}>
              <Text fontWeight={'semibold'} fontSize={'sm'} w={'100px'}>
                Firstname
              </Text>
              <Text fontWeight={'light'} fontSize={'sm'}>
                {user?.first_name ? user?.first_name : 'Not provided'}
              </Text>
            </HStack>
            <HStack mb={'5px'} alignItems={'start'}>
              <Text fontWeight={'semibold'} fontSize={'sm'} w={'100px'}>
                Lastname
              </Text>
              <Text fontWeight={'light'} fontSize={'sm'}>
                {user?.last_name ? user?.last_name : 'Not provided'}
              </Text>
            </HStack>
            <HStack mb={'5px'} alignItems={'start'}>
              <Text fontWeight={'semibold'} fontSize={'sm'} w={'100px'}>
                Email Address
              </Text>
              <Text fontWeight={'light'} fontSize={'sm'}>
                {user?.email ? user?.email : 'Not provided'}
              </Text>
            </HStack>
            <HStack mb={'5px'} alignItems={'start'}>
              <Text fontWeight={'semibold'} fontSize={'sm'} minW={'100px'}>
                Web3 address
              </Text>
              <Text fontWeight={'light'} fontSize={'sm'}>
                {user?.web3_account}
              </Text>
            </HStack>
            <Divider my={'10px'} />
            {/* <Text fontSize={'2xl'} fontWeight={'semibold'} mb={'20px'}>
              Statistic
            </Text>
            <StatGroup>
              <Stat>
                <StatLabel>Issuers</StatLabel>
                <StatNumber>345,670</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  23.36%
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Claimed Tokens</StatLabel>
                <StatNumber>45</StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  9.05%
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Claimed Vouchers</StatLabel>
                <StatNumber>45</StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  9.05%
                </StatHelpText>
              </Stat>
            </StatGroup> */}
          </Box>
        </HStack>
      </Show>
      <Show below={'sm'}>
        <HStack p={'20px'} alignItems={'start'} gap={'20px'}>
          <Avatar
            size={'lg'}
            name={user?.display_name ? user?.display_name : user?.email}
            bgColor={'coap.blue.5'}
            color={'#ffffff'}
          />
          <VStack
            flex={'1'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Box>
              <Text fontWeight={'semibold'} fontSize={'xl'}>
                {user?.display_name}
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {user?.email}
              </Text>
            </Box>
          </VStack>
        </HStack>
        <Box p={'0px 20px 0px 20px'}>
          <Text fontWeight={'semibold'} fontSize={'sm'}>
            ID
          </Text>
          <Text fontWeight={'light'} fontSize={'sm'} mb={'10px'}>
            {user?.id}
          </Text>
          <Text fontWeight={'semibold'} fontSize={'sm'}>
            Web3 Address
          </Text>
          <Text fontWeight={'light'} fontSize={'sm'}>
            {user?.web3_account}
          </Text>
        </Box>
        <Divider my={'10px'} />
        <HStack
          onClick={() => navigate('/profile/edit')}
          p={'0px 20px 0px 20px'}
          h={'30px'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text>Edit Profile</Text>
          <ChevronRightIcon />
        </HStack>
        <HStack
          my={'10px'}
          h={'30px'}
          p={'0px 20px 0px 20px'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => null}
        >
          <Text>Logout</Text>
          <ChevronRightIcon />
        </HStack>
      </Show>
    </Container>
  );
}
