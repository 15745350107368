import { extendTheme, ToastOptions } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    coap: {
      blue: {
        1: '#478AE1',
        2: '#D0F4FC',
        3: '#12577F',
        4: '#238D87',
        5: '#259DEC',
        6: '#28B5E8',
      },
      grey: {
        1: '#ECF1F4',
      },
      orange: {
        1: '#F1672F',
      },
      yellow: {
        1: '#FFDF1A',
        2: '#F5D511',
      },
      green: {
        1: '#26C6CE',
        2: '#048547',
        3: '#007C89',
      },
    },
  },
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
});

export default theme;
